import React from "react";

export default ({ children, style }) => {
  return (
    <p
      style={{
        fontSize: "1.9em",
        margin: "42px 0 10px 0",
        color: "#6b6048",
        fontFamily: "FishardsMedium",
        ...style,
      }}
    >
      {children}
    </p>
  );
};
