import React from "react";
import { NavLink } from "react-router-dom";
import FishardsSmallText from "./FishardsSmallText";

export default ({ to, style, exact, children }) => (
  <FishardsSmallText style={{ margin: 0 }}>
    <NavLink
      to={to}
      exact={exact}
      style={{
        color: "grey",
        textDecoration: "none",
        fontWeight: 500,
        ...style,
      }}
      activeStyle={{
        color: "black",
      }}
    >
      {children}
    </NavLink>
  </FishardsSmallText>
);
