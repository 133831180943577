import React from "react";
// Material UI
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

// Custom components
import { Logo, StyledNavLink, DropDown } from "../components/general";
import { getWindowSize } from "../util";
import ElementDivider from "../components/general/ElementDivider";
import FishardsSmallText from "../components/general/FishardsSmallText";

const navigationRoutes = [
  { to: "/", title: "Home" },
  { to: "/about", title: "About" },
  { to: "/presskit", title: "Presskit" },
];

export default () => {
  return (
    <div style={{ width: "100%", margin: "40px 0" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <StyledNavLink exact to="/">
            <Logo />
          </StyledNavLink>
        </Grid>
        <NavBar />
        <ElementDivider style={{ marginTop: 10 }} />
      </Grid>
    </div>
  );
};

const NavBar = () => {
  return (
    <Grid item style={{ marginTop: 8, width: "100%", maxWidth: 200 }}>
      <Grid container justifyContent="space-between">
        {navigationRoutes.map((navigationInfo) => {
          return (
            <Grid item>
              <StyledNavLink exact to={navigationInfo.to}>
                {navigationInfo.title}
              </StyledNavLink>
            </Grid>
          );
        })}
        {/*<ToggleLanguage />*/}
      </Grid>
    </Grid>
  );
};
