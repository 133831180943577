import React from "react";
import Grid from "@material-ui/core/Grid";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailIcon from "@material-ui/icons/Mail";
// Custom components
import { StyledLink } from "../components/general";
import { Twitter } from "@material-ui/icons";
import FishardsMediumText from "../components/general/FishardsMediumText";
import FishardsSmallText from "../components/general/FishardsSmallText";

const date = new Date();

export default () => (
  <>
    <Grid container style={{ paddingTop: 30, paddingBottom: 20 }}>
      <Grid item style={{ marginRight: "5%" }}>
        <FishardsMediumText style={{ marginBottom: 4 }}>
          Contact us
        </FishardsMediumText>
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:rivernotch@gmail.com"
        >
          <FooterLink text="rivernotch@gmail.com" />
        </StyledLink>
      </Grid>

      <Grid item style={{ marginRight: "5%" }}>
        <FishardsMediumText style={{ marginBottom: 4 }}>
          Follow us!
        </FishardsMediumText>
        <StyledLink href="https://www.instagram.com/fishards/">
          <FooterLink text="Instagram" />
        </StyledLink>
        <StyledLink href="https://twitter.com/rivernotch">
          <FooterLink text="Twitter" />
        </StyledLink>
        <StyledLink href="https://discord.gg/DZJKRaKscY">
          <FooterLink text="Discord" />
        </StyledLink>
      </Grid>
    </Grid>

    <Grid
      container
      alignItems="center"
      style={{
        paddingTop: 12,
        opacity: 0.7,
        fontSize: 12,
      }}
    >
      <Grid item>
        <FishardsSmallText>Fishards {date.getFullYear()}</FishardsSmallText>
      </Grid>
    </Grid>
  </>
);

const FooterLink = ({ icon, text }) => (
  <div>
    <div style={{ display: "inline-block", verticalAlign: "middle" }}>
      {icon}
    </div>
    <div
      style={{
        marginLeft: 4,
        marginBottom: 4,
        display: "inline-block",
        verticalAlign: "middle",
      }}
    >
      {text}
    </div>
  </div>
);
