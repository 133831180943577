import React from "react";

export default ({ children, style }) => {
  return (
    <p
      style={{
        fontSize: "1.9em",
        margin: "10px 0",
        lineHeight: "80%",
        color: "#87795c",
        ...style,
      }}
    >
      {children}
    </p>
  );
};
