import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#e3e2d2",
    outline: "none",
    borderRadius: 4,
  },
}));

const TransitionsModal = ({ open, setOpen, children }) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <CloseIcon
                  onClick={() => setOpen(false)}
                  style={{
                    cursor: "pointer",
                    fontSize: 22,
                    marginRight: 20,
                  }}
                />
              </Grid>
              <Grid item style={{ cursor: "pointer", width: 50 }} />
            </Grid>
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
