import React, { useState } from "react";
import FishardsMediumText from "./FishardsMediumText";

export default ({
  children,
  style,
  color = "#6b6048",
  hoverColor = "#d1c39f",
  type,
  onClick,
}) => {
  const [hoveringOver, setHoveringOver] = useState(false);

  return (
    <button
      onMouseEnter={() => setHoveringOver(true)}
      onMouseLeave={() => setHoveringOver(false)}
      onClick={onClick}
      type={type}
      style={{
        borderRadius: 0,
        borderStyle: "solid",
        cursor: "pointer",
        borderWidth: 3,
        borderColor: hoveringOver ? hoverColor : color,
        padding: 10,
        display: "inline-block",
        margin: 0,
        background: "rgba(0.5, 0.5, 0.5, 0.04)",
        ...style,
      }}
    >
      <FishardsMediumText
        style={{
          color: hoveringOver ? hoverColor : color,
          textAlign: "center",
          margin: 0,
        }}
      >
        {children}
      </FishardsMediumText>
    </button>
  );
};
