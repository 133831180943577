import React from "react";
import FishardsSmallText from "./FishardsSmallText";
// Custom

export default (props) => (
  <FishardsSmallText style={{ margin: 0 }}>
    <a
      {...props}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        fontWeight: 500,
        textDecoration: "none",
        cursor: "pointer",
        color: "#4f5784",
        margin: 0,
        ...props.style,
      }}
    >
      {props.children}
    </a>
  </FishardsSmallText>
);
