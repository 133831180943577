import React from "react";
// Custom
import { Logo } from ".";
import FishardsMediumText from "./FishardsMediumText";

const Loading = ({ fullScreen }) => {
  return (
    <div
      style={{
        flex: 1,
        minHeight: fullScreen ? "100vh" : 0,
        marginTop: fullScreen ? "20vh" : 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "40vh",
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
        }}
      >
        <FishardsMediumText style={{ marginTop: 12 }}>
          Loading...
        </FishardsMediumText>
      </div>
    </div>
  );
};

export default Loading;
