import React from "react";
import { getWindowSize } from "../../util";

const large_logo = "https://imgur.com/oTLsN4H.png";
const small_logo = "https://imgur.com/3f67Qup.png";

const Logo = ({ small }) => {
  const windowSize = getWindowSize();
  return (
    <img
      src={windowSize.width < 700 || small === true ? small_logo : large_logo}
      alt="Fishards"
    />
  );
};

export default Logo;
