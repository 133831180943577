// Third party
import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
// Custom components
import { Loading } from "../components/general";
import Footer from "../containers/Footer";
import Header from "../containers/Header";
import { getWindowSize } from "../util";
// Lazy loading
const Home = lazy(() => import("../containers/Home.jsx"));
const About = lazy(() => import("../containers/About.jsx"));
const PressKit = lazy(() => import("../containers/PressKit.jsx"));
const PageNotFound = lazy(() => import("../containers/PageNotFound.jsx"));

const TreeWrapper = ({ children }) => {
  const windowSize = getWindowSize();
  const treeImageHeight = 720;
  const treeImageWidth = 140;

  let renderWidth = treeImageWidth;
  let renderHeight = treeImageHeight;

  if (windowSize.width < 520) {
    renderWidth = treeImageWidth / 3;
    renderHeight = renderHeight / 3;
  } else if (windowSize.width < 750) {
    renderWidth = treeImageWidth / 1.5;
    renderHeight = renderHeight / 1.5;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          minWidth: renderWidth,
          backgroundImage: "url('https://imgur.com/6UziBhI.png')",
          backgroundRepeat: "repeat-y",
          backgroundSize: renderWidth + "px " + renderHeight + "px",
        }}
      />
      <div style={{ maxWidth: 600, width: "100%", margin: "0 8px" }}>
        {children}
      </div>
      <div
        style={{
          minWidth: renderWidth,
          backgroundImage: "url('https://imgur.com/uatDYV4.png')",
          backgroundRepeat: "repeat-y",
          backgroundSize: renderWidth + "px " + renderHeight + "px",
        }}
      />
    </div>
  );
};

const Boiler = () => (
  <TreeWrapper>
    <Header />
    <Suspense fallback={<Loading fullScreen />}>
      <Switch>
        <Route path="/" exact render={() => <Home />} />
        <Route path="/about" render={() => <About />} />
        <Route path="/presskit" render={() => <PressKit />} />
        <Route render={(props) => <PageNotFound {...props} />} />
      </Switch>
    </Suspense>
    <Footer />
  </TreeWrapper>
);

export default Boiler;
